import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams, useLoaderData} from "react-router-dom";
import HotelCard from '../../Components/HotelCard/HotelCard';
import Skeleton from '@mui/material/Skeleton';
import CityCard from '../../Components/CityCard/CityCard';
import CardContent from '@mui/material/CardContent';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SearchPage() {

  const {query} = useParams();
  let storedSearchResult = JSON.parse(sessionStorage.getItem("storedSearchResult_"+query.toLowerCase()));

  const [searchResult, setSearchResult] = useState(storedSearchResult);
  const hotelsEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}, {"id" : "4"}];

  if (query)
  {
    let searchResultStored = true;

    if (!storedSearchResult)
    {
      searchResultStored = false;
      storedSearchResult = [];
    }

    if (!searchResultStored)
    {
      fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/search/?q='+query.toLowerCase().replace(" ","-"))
      .then((response) => response.json())
      .then((data) => {
        setSearchResult(data.hits.hit); 
        sessionStorage.setItem("storedSearchResult_"+query.toLowerCase(), JSON.stringify(data.hits.hit));
        console.log(data)
      })
      .catch((err) => {
        console.log(err.message);
      });
    }
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
       <main>
        {/* Hero unit */}
        
          {(query) ? (
        <Container>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Results for '{query}'
            </Typography>

            <Typography></Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
        {/* filter example .filter((item) => item.city === "london") */}
        <Grid container spacing={4}>
          {searchResult ? (searchResult.sort((a, b) => a.fields.hotelrating > b.fields.hotelrating ? -1 : 1).slice(0, 48).map((card) => (
            <HotelCard id={card.fields.id} xs={12} sm={4} md={3} stay={card.fields.stay} city={card.fields.city} hotelRating={card.fields.hotelrating} country={card.fields.country} image={card.fields.image} short={card.fields.short} hotelName={card.fields.hotelname} reviewsCount={card.fields.reviewscount} />
          ))) : (
            hotelsEmptyState.map((card) => (
              <Grid item key={card.id} xs={12} sm={4} md={3}>
                <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
              >
                  <Skeleton variant="rectangular" sx={{
                        // 16:9
                        pt: '56.25%',
                      }} />
                  <CardContent sx={{ flexGrow: 1 }}>
                      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                      <Skeleton variant="rectangular" width="100%" height={100} />
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
      </Container>
        ) :
        (
          <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Search Page
            </Typography>
            
            <Typography align="left" color="text.secondary" paragraph>content</Typography>

          </Container>
        </Box>
        )}
  
      </main>
    </ThemeProvider>
  );
}