import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from 'react';

export default function SearchBar() {

    const [hotelsList, setSearchValue] = React.useState([]);

    const onSubmit = e => {
        e.preventDefault();
        
        if (e.target[0].value.length > 0)
        {
          window.location.href = "/search/"+e.target[0].value;
        }
    };

  return (
    <Paper
      component="form" onSubmit={onSubmit}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '40%' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Hotels"
        inputProps={{ 'aria-label': 'Search Hotels' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSubmit}>
        <SearchIcon />
      </IconButton>
      
    </Paper>
  );
}