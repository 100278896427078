
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import AppBar from '@mui/material/AppBar';
import BedIcon from '@mui/icons-material/Bed';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import { fontWeight } from '@mui/system';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Rating from '@mui/material/Rating';
import Skeleton from '@mui/material/Skeleton';

import './CityCard.css';

function toTitleCase(str) {
  try {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  } catch (error) {
    return str;
  }
}

// TODO MOVE TO A MODULE
function stripDash(str) {
  try{
    return str.replace("-"," ");
  }
  catch(error){
    return str;
  }
}

 export default function CityCard(props) {

    return (
      <Grid item key={props.id} xs={props.xs} sm={props.sm} md={props.md}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={props.image}>
                    </CardMedia>
                 
                  
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    <Link href={props.countryUrl+"/"+props.id}>
                     Hotels In {props.cityName}
                    </Link>
                    </Typography>
                    <Typography>
                    {props.short}
                    </Typography>
                  </CardContent>
                </Card>
      </Grid>
    );
  }