import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import {useParams, useLoaderData} from "react-router-dom";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import HotelCard from '../../Components/HotelCard/HotelCard';
import ReviewsTab from '../../Components/ReviewsTab/ReviewsTab';
import { Helmet } from "react-helmet"


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function toTitleCase(str) {
  try {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  } catch (error) {
    return str;
  }
}

function stripDash(str) {
  try{
    return str.replace(new RegExp("-", 'g')," ");
  }
  catch(error){
    return str;
  }
}


export default function Hotel() {

  // Load cities data from the router api call
  //const loaderData = useLoaderData();
  const {hotelName} = useParams();
  const {city} = useParams();
  const {country} = useParams();

  // Load the hotel data
  let storedHotel = JSON.parse(sessionStorage.getItem("hotel_"+hotelName));
  let hotelStored = true;
  const hotelImagesEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}, {"id" : "4"},{"id" : "5"}, {"id" : "6"}, {"id" : "7"}, {"id" : "8"},{"id" : "9"}, {"id" : "10"}, {"id" : "11"}, {"id" : "12"}];

  if (!storedHotel)
  {
    hotelStored = false;
    storedHotel = {};
  }

  const [hotel, setHotel] = useState(storedHotel);

   if (!hotelStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/' + hotelName + '/' + '?city=' + city)
         .then((response) => response.json())
         .then((data) => {

            setHotel(data); 
            storedHotel = data;
            sessionStorage.setItem("hotel_"+hotelName, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            window.location.href = "/search/"+stripDash(hotelName);
         });
 //  }, []);
  }


  // Load hotels data
  let storedHotels = JSON.parse(sessionStorage.getItem("hotels_city_true_"+city));
  let hotelsStored = true;
  const hotelsEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}, {"id" : "4"}];

  if (!storedHotels)
  {
    hotelsStored = false;
    storedHotels = [];
  }

  const [hotels, setHotels] = useState(storedHotels);

   if (!hotelsStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/?city=' + city + '&stay=true')
         .then((response) => response.json())
         .then((data) => {

            setHotels(data); 
            storedHotels = data;
            sessionStorage.setItem("hotels_city_true_"+city, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedHotels = [];
         });
 //  }, []);
  }

  const metaDescription = "Our bottom line for Hotel " + stripDash(toTitleCase(hotelName)) + " - " + hotel.short;
  const pageTitle = "Hotel " + stripDash(toTitleCase(hotelName)) + ", " + stripDash(toTitleCase(city)) + ", " + stripDash(toTitleCase(country)) + " - Our bottom line recommendation based on " + hotel.reviewsCount + " reviews";
  const canonical = "https://www.stayoraway.com/" + country + "/" + city + "/" + hotelName;

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Helmet>
          <title>{pageTitle}</title>
           <meta name="description" content={metaDescription}></meta>
           <link rel="canonical" href={canonical} />
        </Helmet>
        
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            {hotel.id == hotelName ? (
              <Typography
                component="h1"
                variant="h4"
                align="left"
                color="text.primary"
              >
                {hotel.hotelName}
                <Chip sx={{fontWeight:"bold", marginLeft: "10px", position: "relative", top: "-3px"}} label={hotel.stay ? "Stay" : "Stay Away"} color={hotel.stay ? "success" : "error"} />
                <Rating sx={{marginLeft: "10px"}} name="read-only" value={hotel.hotelRating} precision={0.5} readOnly />
              </Typography>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            )}
            {hotel.id == hotelName ? (
            <Typography>
              <LocationOnRoundedIcon sx={{position: "relative", top: "5px"}} />
              {hotel.hotelAddress}
            </Typography>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%' }} />
            )}
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2, pb: 2 }} maxWidth="lg">
          <Grid container spacing={2}>


          {hotel.id == hotelName ? (
            <Grid item xs={12} md={5}>
              <Container sx={{ py: 8, pt: 2, pb: 2 }} maxWidth="lg">
              
              <Button sx={{marginBottom:"10px"}} variant="contained" href={"https://www.dpbolvw.net/click-101140627-13829783?url=" + encodeURIComponent("https://www.hotels.com/Hotel-Search?selected="+hotel.hotelId+"&sort=RECOMMENDED")} target="_blank">BOOK NOW</Button>
              
              <Typography component="h2" variant="h6">
                Our Bottom Line, Based on {hotel.reviewsCount} Reviews:
              </Typography>
              <Typography>
                {hotel.short}
              </Typography>
              <Divider sx={{marginTop:"15px", marginBottom: "15px"}}></Divider>
              <Typography paragraph  dangerouslySetInnerHTML={{__html: hotel.content}}>
              </Typography>
              <Typography>
                <Link href={"https://www.dpbolvw.net/click-101140627-13829783?url=" + encodeURIComponent("https://www.hotels.com/Hotel-Search?selected="+hotel.hotelId+"&sort=RECOMMENDED")} target="_blank">Read all reviews</Link>
              </Typography>
              </Container>
            </Grid>
          ) : (
            <Grid item xs={12} md={5}>
              <Skeleton variant="text" sx={{ fontSize: '4rem', width: '30%' }} />
              <Skeleton variant="rectangular" width="90%" height="70%" />
            </Grid>
          )}
            <Grid item xs={12} md={7}>
              <ImageList sx={{ width: "100%", height: 450 }} variant="quilted" cols={4} rowHeight={164}>
              {hotel.id == hotelName ? hotel.hotelImageGallery.images.map((item) => 
              (
                <ImageListItem key={item.imageId}>
                  <img
                    srcSet={`${item.image.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.image.url}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.image.description}
                    loading="lazy"
                  />
                </ImageListItem>
              )) : (
                hotelImagesEmptyState.map((image) => (
                  <ImageListItem key={image.id}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                  </ImageListItem> 
                ))
              )}
              </ImageList>
            </Grid>
          </Grid>
        </Container>

        {/* Start points to note section */}
        {(hotel.categories) ? (
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
            >
              More about {hotel.hotelName}
            </Typography>
            <Typography>
              {hotel.hotelNewContent}
            </Typography>
          </Container>
        </Box>
        ) : (
          <Container></Container>
        )}
        {(hotel.categories) ? (
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          {
            hotel.categories.map((category) => (
              <Box sx={{marginBottom: "5px"}}>
                <Chip variant="outlined" color="info" label={category.category} sx={{marginRight: "5px"}}/>
                {category.reason}
              </Box>
          ))}
        </Container>
        ) : (
          <Container></Container>
        )}

        {/* Start Reviews section */}
        {(hotel.lastReviews) ? (
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
            >
              Recent Reviews For {hotel.hotelName}
            </Typography>
            <Typography>
              We brought to you only the most recent real guests reviews, which are considered as part of our bottom line reccommendation to stay or avoid this hotel.
            </Typography>
          </Container>
        </Box>
        ) : (
          <Container></Container>
        )}
        {(hotel.lastReviews) ? (
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          <ReviewsTab lastReviews={hotel.lastReviews}></ReviewsTab>
        </Container>
        ) : (
          <Container></Container>
        )}

        {/* Start Hotels section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Hotels worth stay at {stripDash(toTitleCase(city))}
            </Typography>
            <Typography>
              Find out Which hotels to stay at in {stripDash(toTitleCase(city))}, based on thousands of reviews.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          
          <Grid container spacing={4}>
          {hotels.length > 0 ? (hotels.sort((a, b) => a.hotelRating > b.hotelRating ? -1 : 1).slice(0, 24).map((card) => (
              <HotelCard id={card.id} xs={12} sm={4} md={3} stay={card.stay} city={card.city} hotelRating={card.hotelRating} country={card.country} image={card.image} short={card.short} hotelName={card.hotelName} reviewsCount={card.reviewsCount} />
            ))) : (
              hotelsEmptyState.map((card) => (
                <Grid item key={card.id} xs={12} sm={4} md={3}>
                  <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                >
                    <Skeleton variant="rectangular" sx={{
                          // 16:9
                          pt: '56.25%',
                        }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={100} />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}