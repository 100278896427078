
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import BedIcon from '@mui/icons-material/Bed';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import { fontWeight } from '@mui/system';
import SearchBar from '../SearchBar/SearchBar';
import Link from '@mui/material/Link';
import './Header.css';

 export default function Header() {

    return (
      <>
      <AppBar position="static">
        <Toolbar>
          <BedIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }} noWrap>
            <Link href='/' className='Logo-link'>StayOrAway</Link>
          </Typography>
          <SearchBar></SearchBar>
        </Toolbar>
      </AppBar>
      <Alert severity="success">Based on thousands of reviews and sources of data, Our AI helps you get the bottom line - Should you <b>Stay</b> in an Hotel, or <b>Stay Away</b> from it!</Alert>
    </>
    );
  }