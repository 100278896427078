import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams, useLoaderData} from "react-router-dom";
import HotelCard from '../../Components/HotelCard/HotelCard';
import Skeleton from '@mui/material/Skeleton';
import CityCard from '../../Components/CityCard/CityCard';
import CardContent from '@mui/material/CardContent';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function CountryPage() {

  const {country} = useParams();
  const loaderData = useLoaderData();

  let storedHotels = JSON.parse(sessionStorage.getItem("hotels_country_"+country));
  let hotelsStored = true;

  if (!storedHotels)
  {
    hotelsStored = false;
    storedHotels = [{"id": "1", "image":"https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AYoutube_loading_symbol_1_%2528wobbly%2529.gif&psig=AOvVaw0Yljj8YRSCQ3tByRrhj09q&ust=1709152547116000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOjA_MSvzIQDFQAAAAAdAAAAABAE"},{"id": "2", "image":"https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AYoutube_loading_symbol_1_%2528wobbly%2529.gif&psig=AOvVaw0Yljj8YRSCQ3tByRrhj09q&ust=1709152547116000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOjA_MSvzIQDFQAAAAAdAAAAABAE"},{"id": "3", "image":"https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AYoutube_loading_symbol_1_%2528wobbly%2529.gif&psig=AOvVaw0Yljj8YRSCQ3tByRrhj09q&ust=1709152547116000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOjA_MSvzIQDFQAAAAAdAAAAABAE"}];
  }

  const [hotels, setHotels] = useState(storedHotels);

   if (!hotelsStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/?limit=48&country='+country)
         .then((response) => response.json())
         .then((data) => {

            setHotels(data); 
            storedHotels = data;
            sessionStorage.setItem("hotels_country_"+country, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedHotels = [];
         });
 //  }, []);
  }

  // Load cities data from the router api call
  let storedCities = JSON.parse(sessionStorage.getItem("cities_in_"+country));
  let citiesStored = true;

  if (!storedCities)
  {
    citiesStored = false;
    storedCities = [];
  }

  const [cities, setCities] = useState(storedCities);
  const citiesEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}];

  if (!citiesStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/cities/?country='+country)
         .then((response) => response.json())
         .then((data) => {

            setCities(data); 
            storedCities = data;
            sessionStorage.setItem("cities_in_"+country, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedCities = [];
         });
   //}, []);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
       <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              {loaderData.countryName}
            </Typography>
            
            <Typography align="left" color="text.secondary" paragraph  dangerouslySetInnerHTML={{__html: loaderData.html}}></Typography>

          </Container>
        </Box>
        
        {/* Cities Section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Popular Destinations in {loaderData.countryName}
            </Typography>
            <Typography>
              Find out Which hotels to stay at and which hotels to avoid in the most popular cities in {loaderData.countryName}.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2 }} maxWidth="lg">
          <Grid container spacing={4}>
            {cities.length > 0 ? cities.slice(0, 12).map((card) => (
              
              <CityCard id={card.id} xs={12} sm={6} md={4} image={card.image} countryUrl={card.countryUrl} cityName={card.cityName} short={card.short} ></CityCard>
              
              )) : citiesEmptyState.map((city) => (
                <Grid item key={city.id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                  >
                    <Skeleton variant="rectangular" sx={{
                        // 16:9
                        pt: '56.25%',
                      }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                      <Skeleton variant="rectangular" width="100%" height={150} />
                    </CardContent>
                  </Card>
                </Grid>
              
            ))}
          </Grid>
        </Container>
        {/* END cities section */}

        {/* Hotels section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Hotels in {loaderData.countryName}
            </Typography>
            <Typography>
              Find out Which hotels to stay at and which hotels to avoid in {loaderData.countryName}
            </Typography>

          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {storedHotels.sort((a, b) => a.hotelRating > b.hotelRating ? -1 : 1).slice(0, 48).map((card) => (
              <HotelCard id={card.id} xs={12} sm={4} md={3} hotelRating={card.hotelRating} stay={card.stay} city={card.city} country={card.country} image={card.image} short={card.short} hotelName={card.hotelName} reviewsCount={card.reviewsCount} />
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}