import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams, useLoaderData} from "react-router-dom";
import HotelCard from '../../Components/HotelCard/HotelCard';
import CityCard from '../../Components/CityCard/CityCard';
import { Helmet } from "react-helmet"

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function HP() {

  // Load cities data from the router api call
  let storedCities = JSON.parse(sessionStorage.getItem("cities"));
  let citiesStored = true;

  if (!storedCities)
  {
    citiesStored = false;
    storedCities = [];
  }

  const [cities, setCities] = useState(storedCities);
  const citiesEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}];

  if (!citiesStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/cities/')
         .then((response) => response.json())
         .then((data) => {

            setCities(data); 
            storedCities = data;
            sessionStorage.setItem("cities", JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedCities = [];
         });
   //}, []);
  }

  // Load countries data
  let storedCountries = JSON.parse(sessionStorage.getItem("countries"));
  let countriesStored = true;

  if (!storedCountries)
  {
    countriesStored = false;
    storedCountries = [];
  }

  const [countries, setCountries] = useState(storedCountries);
  const countriesEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}];

   if (!countriesStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/')
         .then((response) => response.json())
         .then((data) => {

            setCountries(data); 
            storedCountries = data;
            sessionStorage.setItem("countries", JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedCountries = [];
         });
   //}, []);
  }

  // Load hotels data
  let storedHotels = JSON.parse(sessionStorage.getItem("hotels"));
  let hotelsStored = true;

  if (!storedHotels)
  {
    hotelsStored = false;
    storedHotels = [];
  }

  const [hotels, setHotels] = useState(storedHotels);
  const hotelsEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}, {"id" : "4"}];

   if (!hotelsStored)
   {
      
  // useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/?limit=48')
         .then((response) => response.json())
         .then((data) => {

            setHotels(data); 
            storedHotels = data;
            sessionStorage.setItem("hotels", JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedHotels = [];
         });
   //}, []);
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
       <main>
       <Helmet>
          <title>StayOrAway.com - Stay Or Avoid an Hotel, Powered by AI</title>
          <meta name='impact-site-verification' value='1e126d97-5148-43b8-a4b2-c26dec721f94'></meta>
          <meta name="description" content="Find out Which hotels to stay at and which hotels to avoid in the most popular cities in the world."/>
          </Helmet>
       
        {/* Cities Section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Popular Destinations
            </Typography>
            <Typography>
              Find out Which hotels to stay at and which hotels to avoid in the most popular cities in the world.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2 }} maxWidth="lg">
          <Grid container spacing={4}>
            {cities.length > 0 ? cities.slice(0, 6).map((card) => (
              
              <CityCard id={card.id} xs={12} sm={6} md={4} image={card.image} countryUrl={card.countryUrl} cityName={card.cityName} short={card.short} ></CityCard>
              
              )) : citiesEmptyState.map((city) => (
                <Grid item key={city.id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                  >
                    <Skeleton variant="rectangular" sx={{
                        // 16:9
                        pt: '56.25%',
                      }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                      <Skeleton variant="rectangular" width="100%" height={150} />
                    </CardContent>
                  </Card>
                </Grid>
              
            ))}
          </Grid>
        </Container>
        {/* END cities section */}

        {/* Hotels section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Popular Hotels To Stay at or Stay Away From
            </Typography>
            <Typography>
              Find out Which hotels to stay at and which hotels to avoid in the most popular destinations in the world.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          {/* filter example .filter((item) => item.city === "london") */}
          <Grid container spacing={4}>
            {hotels.length > 0 ? (hotels.sort((a, b) => a.hotelRating > b.hotelRating ? -1 : 1).slice(0, 48).map((card) => (
              <HotelCard id={card.id} xs={12} sm={4} md={3} stay={card.stay} city={card.city} hotelRating={card.hotelRating} country={card.country} image={card.image} short={card.short} hotelName={card.hotelName} reviewsCount={card.reviewsCount} />
            ))) : (
              hotelsEmptyState.map((card) => (
                <Grid item key={card.id} xs={12} sm={4} md={3}>
                  <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                >
                    <Skeleton variant="rectangular" sx={{
                          // 16:9
                          pt: '56.25%',
                        }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={100} />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
        {/* End Hotels section */}

        {/* Countries Section */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
            >
              Popular Countries
            </Typography>
            <Typography>
              Find out Which hotels to stay at and which hotels to avoid in the most popular countries in the world.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2 }} maxWidth="lg">
            
          <Grid container spacing={4}>
          
            {countries.length > 0 ? countries.slice(0, 3).map((country) => (
              
              <Grid item key={country.id} xs={12} sm={6} md={4}>
                
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={country.image}>
                    </CardMedia>
                 
                  
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    <Link href={country.id}>
                      Hotels In {country.countryName}
                    </Link>
                    </Typography>
                    <Typography>
                    {country.short}
                    </Typography>
                  </CardContent>
                </Card>
               
              </Grid>
            )) : countriesEmptyState.map((country) => (
                <Grid item key={country.id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                  >
                    <Skeleton variant="rectangular" sx={{
                        // 16:9
                        pt: '56.25%',
                      }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                      <Skeleton variant="rectangular" width="100%" height={150} />
                    </CardContent>
                  </Card>
                </Grid>
                
            ))}
          </Grid>
        </Container>
        {/* END Cuntries Section */}
      </main>
    </ThemeProvider>
  );
}