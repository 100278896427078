import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams, useLoaderData} from "react-router-dom";
import HotelCard from '../../Components/HotelCard/HotelCard';
import Skeleton from '@mui/material/Skeleton';
import { Helmet } from "react-helmet"

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function toTitleCase(str) {
  try {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  } catch (error) {
    return str;
  }
}

function stripDash(str) {
  try{
    return str.replace(new RegExp("-", 'g')," ");
  }
  catch(error){
    return str;
  }
}

export default function CityPage() {

  const {city} = useParams();
  //const loaderData = useLoaderData();

  // Load City Info
  //loader: async ({ params }) => {
  //  return fetch(`https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/cities/${params.city}`);
  //},
  let storedCity = JSON.parse(sessionStorage.getItem("city_"+city));
  let cityStored = true;

  if (!storedCity)
  {
    cityStored = false;
    storedCity = {};
  }

  const [cityFromState, setCity] = useState(storedCity);

   if (!cityStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/cities/' + city)
         .then((response) => response.json())
         .then((data) => {

            setCity(data); 
            storedCity = data;
            sessionStorage.setItem("city_"+city, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedCity = [];
         });
 //  }, []);
  }

  // Load City Hotels
  let storedHotels = JSON.parse(sessionStorage.getItem("hotels_city_"+city));
  let hotelsStored = true;
  const hotelsEmptyState = [{"id" : "1"}, {"id" : "2"}, {"id" : "3"}, {"id" : "4"}];

  if (!storedHotels)
  {
    hotelsStored = false;
    storedHotels = [];
  }

  const [hotels, setHotels] = useState(storedHotels);

   if (!hotelsStored)
   {
      
   //useEffect(() => {
        fetch('https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/?city='+city)
         .then((response) => response.json())
         .then((data) => {

            setHotels(data); 
            storedHotels = data;
            sessionStorage.setItem("hotels_city_"+city, JSON.stringify(data));
         })
         .catch((err) => {
            console.log(err.message);
            storedHotels = [];
         });
 //  }, []);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
       <main>
       <Helmet>
          <title>Hotels to Stay or Avoid in {toTitleCase(stripDash(city))}</title>
           <meta name="description" content={"Hotels to Stay or Stay Away from in " + toTitleCase(stripDash(city)) + ". Get the bottom line from thousands of reviewers."}></meta>
        </Helmet>

        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Hotels in {toTitleCase(stripDash(city))}
            </Typography>
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Hotels to <b>Stay</b> or <b>Stay Away</b> from in {toTitleCase(stripDash(city))}. Get the bottom line from thousands of reviewers.
            </Typography>
        
            { city == cityFromState.id ? (
            <Typography variant="h7" align="left" color="text.secondary" paragraph  dangerouslySetInnerHTML={{__html: cityFromState.html}}>
            </Typography>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            )}
          </Container>
        </Box>
        <Container sx={{ py: 8, pt: 2}} maxWidth="lg">
          {/* filter example .filter((item) => item.city === "london") */}
          <Grid container spacing={4}>
            {hotels.length > 0 ? (hotels.sort((a, b) => a.hotelRating > b.hotelRating ? -1 : 1).slice(0, 100).map((card) => (
              <HotelCard id={card.id} xs={12} sm={4} md={3} stay={card.stay} city={card.city} hotelRating={card.hotelRating} country={card.country} image={card.image} short={card.short} hotelName={card.hotelName} reviewsCount={card.reviewsCount} />
            ))) : (
              hotelsEmptyState.map((card) => (
                <Grid item key={card.id} xs={12} sm={4} md={3}>
                  <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', position:"relative" }}
                >
                    <Skeleton variant="rectangular" sx={{
                          // 16:9
                          pt: '56.25%',
                        }} />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={100} />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}