import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AboutUsPage from './Views/AboutUsPage/AboutUsPage';
import CityPage from './Views/CityPage/CityPage';
import CountryPage from './Views/CountryPage/CountryPage';
import SearchPage from './Views/SearchPage/SearchPage';
import Hotel from './Views/HotelPage/Hotel';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./Views/404/404";
import HP from './Views/HP/HP';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HP />,
    errorElement: <ErrorPage />,
   },
   {
    path: "/about",
    element: <AboutUsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/search/:query",
    element: <SearchPage />,
    errorElement: <ErrorPage />,
  },
   {
    path: "/:country",
    element: <CountryPage />,
    id: "countryPage",
    loader: async ({ params }) => {
      return fetch(`https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/${params.country}`);
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/:country/:city",
    element: <CityPage />,
    id: "cityPage",
    //loader: async ({ params }) => {
    //  return fetch(`https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/cities/${params.city}`);
    //},
    errorElement: <ErrorPage />,
  },
  {
    path: "/:country/:city/:hotelName",
    element: <Hotel />,
    //loader: async ({ params }) => {
    //  return fetch(`https://ev4jl6cqx4.execute-api.us-east-1.amazonaws.com/Prod/hotels/${params.hotelName}?city=${params.city}`);
    //},
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header/>
    <RouterProvider router={router} />
    <Footer/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
