import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Badge, TableBody, Chip, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';

function stringToInitials(str)
{
  let initials = "";

  if (str)
  {
    str = str.split(",");
    str = str[0];

    initials = str.split(" ").map((n)=>n[0]).join("");

  }

  return initials.toUpperCase();
}

function CustomTabPanel(props) {
  const { children, value, index, reviews, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function splitReviewsToTabs(i_ReviewsArr){
  let resArr = {'Poor' : [], 'Fair' : [], 'Good' : [], 'Very good': [], 'Exceptional' : []};

  i_ReviewsArr.forEach(element => {
    if (element.rating.includes("Exceptional")){
      resArr.Exceptional.push(element);
    }
    else if (element.rating.includes("Very good")){
      resArr['Very good'].push(element);
    }
    else if (element.rating.includes("Good")){
      resArr.Good.push(element);
    }
    else if (element.rating.includes("Fair")){
      resArr.Fair.push(element);
    }
    else if (element.rating.includes("Poor")){
      resArr.Poor.push(element);
    }
  });

  return resArr;
}

export default function ReviewsTab(props) {
  const { lastReviews } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  let reviews = splitReviewsToTabs(lastReviews);






  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={"Poor (" + reviews.Poor.length + ")"} {...a11yProps(0)} />
          <Tab label={"Fair (" + reviews.Fair.length + ")"} {...a11yProps(1)} />
          <Tab label={"Good (" + reviews.Good.length + ")"} {...a11yProps(2)} />
          <Tab label={"Very good (" + reviews["Very good"].length + ")"} {...a11yProps(3)} />
          <Tab label={"Exceptional (" + reviews.Exceptional.length + ")"} {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} reviews={reviews.Poor}>
        <Grid container sx={{ marginTop:"5px"}}>
            {reviews.Poor.map((review) => (
              <Card sx={{width:"100%", marginBottom:"15px"}}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
                      {stringToInitials(review.stayDuration)}
                  </Avatar>
                }
                title={review.stayDuration}
                subheader={review.rating}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {review.reviewText}
                </Typography>
              </CardContent>
              
            </Card>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} reviews={reviews.Fair}>
        <Grid container spacing={0} sx={{ marginTop:"5px"}}>
            {reviews.Fair.map((review) => (
              <Card sx={{width:"100%", marginBottom:"15px"}}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
                      {stringToInitials(review.stayDuration)}
                  </Avatar>
                }
                title={review.stayDuration}
                subheader={review.rating}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {review.reviewText}
                </Typography>
              </CardContent>
              
            </Card>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} reviews={reviews.Good}>
        <Grid container spacing={0} sx={{ marginTop:"5px"}}>
              {reviews.Good.map((review) => (
                <Card sx={{width:"100%", marginBottom:"15px"}}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
                        {stringToInitials(review.stayDuration)}
                    </Avatar>
                  }
                  title={review.stayDuration}
                  subheader={review.rating}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {review.reviewText}
                  </Typography>
                </CardContent>
                
              </Card>
              ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3} reviews={reviews["Very good"]}>
      <Grid container spacing={0} sx={{ marginTop:"5px"}}>
            {reviews["Very good"].map((review) => (
              <Card sx={{width:"100%", marginBottom:"15px"}}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
                      {stringToInitials(review.stayDuration)}
                  </Avatar>
                }
                title={review.stayDuration}
                subheader={review.rating}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {review.reviewText}
                </Typography>
              </CardContent>
              
            </Card>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4} reviews={reviews.Exceptional}>
        <Grid container spacing={0} sx={{ marginTop:"5px"}}>
            {reviews.Exceptional.map((review) => (
              <Card sx={{width:"100%", marginBottom:"15px"}}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
                        {stringToInitials(review.stayDuration)}
                    </Avatar>
                  }
                  title={review.stayDuration}
                  subheader={review.rating}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {review.reviewText}
                  </Typography>
                </CardContent>
                
              </Card>
            ))}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
